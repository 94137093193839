import * as React from "react";
import {
  Card,
  CardContent,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import ItemCounter from "../ItemCounter";
import { useContext } from "react";
import CartContext from "../../context/CartContext";

type IItem = {
  name: string;
  pricePerOne: number;
  count: number;
  note: string;
};

const OrderItemCard = (props: {
  item: IItem;
  index: number;
  onDelete: (index: number) => void;
}) => {
  const { changeNote, changeCount } = useContext(CartContext);

  const onNoteChange = (index: number, newNote: string) => {
    changeNote(index, newNote);
  };

  const onCountChange = (index: number, newCount: number) => {
    changeCount(index, newCount);
  };

  return (
    <Card
      sx={{
        padding: 1.5,
      }}
    >
      <CardContent
        sx={{
          p: 0,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            mb: 0.5,
          }}
        >
          <strong>{props.item.name}</strong>
        </Typography>
        <Typography>
          {new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: "CZK",
            maximumFractionDigits: 0,
          }).format(props.item.pricePerOne * props.item.count)}
          /{props.item.count == 1 ? "" : props.item.count}ks
        </Typography>
        <Input
          placeholder="přidejte poznámku (nepovinné)"
          value={props.item.note}
          disableUnderline
          sx={{
            border: "1px solid #F0F0F0",
            width: "100%",
            my: 1.5,
          }}
          onChange={(e) => onNoteChange(props.index, e.target.value)}
        />
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          gap={1}
          sx={{
            height: "40px",
            width: "100%",
          }}
        >
          <ItemCounter
            count={props.item.count}
            onChange={(newCount: number) =>
              onCountChange(props.index, newCount)
            }
          />
          <IconButton
            sx={{
              marginLeft: "auto",
              backgroundColor: "background.default",
            }}
            onClick={() => {
              if (confirm("Smazat tuto položku?")) {
                props.onDelete(props.index);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OrderItemCard;
