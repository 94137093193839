import * as React from "react";
import { Container } from "@mui/material";

import OrderPhaseIndicator from "../components/Order/OrderPhaseIndicator";
import Cart from "../components/Order/Cart";
import CartFoot from "../components/CartFoot";

const Contact = () => {
  return (
    <>
      <Container>
        <OrderPhaseIndicator phase={0} />
        <Cart />
        <CartFoot buttonText={"Pokračovat"} buttonUrl="/contact" />
      </Container>
    </>
  );
};

export default Contact;
