import * as React from "react";
import { useContext } from "react";
import { Grid } from "@mui/material";

import OrderItemCard from "./OrderItemCard";
import CartContext from "../../context/CartContext";

const Cart = () => {
  const { cartContent, deleteItem } = useContext(CartContext);

  const onItemDelete = (index: number) => {
    deleteItem(index);
  };

  return (
    <Grid
      container
      spacing={1.5}
      direction="row"
      justifyContent="flex-start"
      sx={{ flexGrow: 1, mb: "80px" }}
    >
      {cartContent.map((item, index) => (
        <Grid
          item
          key={`${item.name}${item.pricePerOne}`}
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: index % 2 == 0 ? "flex-end" : "flex-start",
          }}
        >
          <OrderItemCard
            item={item}
            index={index}
            key={index}
            onDelete={onItemDelete}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cart;
